import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridItem: {
    marginBottom: theme.spacing(4),
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  subtitle: {
    color: theme.palette.text.primary,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
}));

const ContactPage = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  Contact
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant="h4">
                <strong>Email</strong>
              </Typography>
              <Typography variant="subtitle">
                <a className={classes.subtitle} href="mailto:contact@app-mockup.com">
                  contact@app-mockup.com
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant="h4">
                <strong>Facebook</strong>
              </Typography>
              <Typography variant="subtitle">
                <a className={classes.subtitle} href="https://facebook.com/appmockup" target="_blank" rel="noopener noreferrer">
                  https://facebook.com/appmockup
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant="h4">
                <strong>Twitter</strong>
              </Typography>
              <Typography variant="subtitle">
                <a className={classes.subtitle} href="https://twitter.com/appmockup" target="_blank" rel="noopener noreferrer">
                  https://twitter.com/appmockup
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant="h4">
                <strong>Instagram</strong>
              </Typography>
              <Typography variant="subtitle">
                <a className={classes.subtitle} href="https://instagram.com/appmockup" target="_blank" rel="noopener noreferrer">
                  https://instagram.com/appmockup
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
